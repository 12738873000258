import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { Container } from "react-bootstrap";
import parse from "html-react-parser";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Hero from "../components/Hero";

const PageTemplate = function ({ data: { appSetting, page } }) {
  return (
    <Layout appSettingLocale={appSetting}>
      <Hero
        title={page.hero?.title ? page.hero.title : page.title}
        description={page.hero?.description && page.hero.description}
        background={
          page.hero?.background && page.hero.background.localFile.publicURL
        }
      />
      <section className="ttm-row-page">
        <Container>{parse(page.content.data.content)}</Container>
      </section>
    </Layout>
  );
};

PageTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default PageTemplate;

export const Head = ({
  location,
  data: {
    page: { title, localizations },
  },
}) => (
  <Seo
    title={title}
    localizations={localizations.data}
    pathname={location.pathname}
  />
);

export const pageQuery = graphql`
  query ($id: String!, $locale: String!) {
    page: strapiPage(id: { eq: $id }) {
      locale
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      title
      content {
        data {
          content
        }
      }
    }
    appSetting: strapiAppSetting(locale: { eq: $locale }) {
      headerNav {
        strapi_json_value {
          label
          url
          subMenu {
            label
            url
          }
        }
      }
      footerNav {
        strapi_json_value {
          label
          subMenu {
            label
            url
          }
        }
      }
      footerContactTitle
      footerContactContent {
        data {
          footerContactContent
        }
      }
      copyrightLinks {
        strapi_json_value {
          label
          url
        }
      }
    }
  }
`;
